import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Partenaires from "components/Partenaires";

const Probleme = ({ pageContext: content, location }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _partners,
    _staticTexts,
    _topMenus,
    _companyMenus,
    _lang,
    _richTextBlock,
    _blogs,
  } = content;

  // Get Sidebar content from Contentful
  const getSidebarContent = useMemo(() => {
    const newSidebarContent = {
      title: "",
    };

    let _sidebarContent = _richTextBlock.find(
      (tb) => tb.title === "sidebar_other_countries"
    );

    if (_sidebarContent) {
      try {
        _sidebarContent = _sidebarContent.richText.content;

        newSidebarContent.title = _sidebarContent[0].content[0].value;
      } catch (error) {
        console.log(error);
      }
    }

    return newSidebarContent;
  }, [_richTextBlock]);

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      ratingValue={content.ratingValue || 4.9}
      ratingCount={content.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{_staticTexts.templateProblemH1} | Losali Direct</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content={`${_staticTexts.templateNousContacterH1} | Losali Direct`}
        />
        <meta
          name="description"
          content={`${_staticTexts.templateNousContacterH1} | Losali Direct`}
        />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="page2-box1 card-primary">
          <div className="aide other-card page2-card-left">
            <form
              name="probleme-de-connexion"
              method="POST"
              action="/merci"
              data-netlify="true"
            >
              <h1 className="main-card-h1">{_staticTexts.templateProblemH1}</h1>

              <h4>{_staticTexts.templateProblemPart1}</h4>

              <br />

              <div className="radio-group">
                <h4>
                  <input
                    type="radio"
                    name="probleme"
                    value="L’appel a été très court (moins de 10 secondes). Vous n’avez rien entendu / tonalité « occupé » / voix dans la langue locale expliquant que vous ne pouvez pas appeler ce numéro ou que le numéro est erroné."
                    required
                  />
                  {_staticTexts.templateProblemSelection11}
                </h4>
                <br />

                <h4>
                  <input
                    type="radio"
                    name="probleme"
                    value="Votre appel s’interrompt après que vous ayez entendu un message d’accueil mentionnant entre autre le prix de la communication dans la langue locale."
                    required
                  />
                  {_staticTexts.templateProblemSelection21}
                </h4>
                <br />

                <h4>
                  <input
                    type="radio"
                    name="probleme"
                    className="float-left"
                    value="Vous entendez le message suivant : «Après le bip, veuillez composer le numéro que vous cherchez à joindre suivi de la touche #. Appelez comme si vous étiez en France. », mais n’arrivez pas à joindre votre correspondant."
                    required
                  />
                  {_staticTexts.templateProblemSelection31}
                </h4>
                <br />

                <h4>
                  <input
                    type="radio"
                    name="probleme"
                    value="Vous avez été connecté(e), mais la qualité sonore de l'appel était mauvaise."
                    required
                  />
                  {_staticTexts.templateProblemSelection41}
                </h4>

                <input
                  type="hidden"
                  name="form-name"
                  value="probleme-de-connexion"
                  required
                />

                <button className="losali-btn" type="submit">
                  {_staticTexts.templateNousContacterSend}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="page2-box2 hide-mobile">
          <div className="other-card-right page2-card-right card-primary">
            <div className="page2-right-text">
              <h3>{getSidebarContent.title}</h3>

              <div className="social-media">
                <span className="social-media-like float-left"></span>
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.losalidirect.com%2F&display=popup&ref=plugin&src=like&kid_directed_site=0"
                  target="_blank"
                  rel="noreferrer"
                  className="social-media-text"
                >
                  {_staticTexts.like}
                </a>
              </div>

              <div className="social-media">
                <span className="social-media-share float-left"></span>
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(`https://losalidirect.com`);
                  }}
                  aria-hidden="true"
                  className="social-media-text"
                >
                  {_staticTexts.share}
                </span>
              </div>

              <div className="social-media">
                <span className="social-media-tweet float-left"></span>
                <a
                  href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Flosalidirect.com%2F&ref_src=twsrc%5Etfw&text=Avec%20Losali%20Direct%20appelez%20les%20num%C3%A9ros%20courts%20et%20surtax%C3%A9s%20depuis%20l%27%C3%A9tranger&tw_p=tweetbutton&url=https%3A%2F%2Fwww.losalidirect.com%2F&via=Narga"
                  target="_blank"
                  rel="noreferrer"
                  className="social-media-text"
                >
                  {_staticTexts.tweet}
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Partenaires partners={_partners} />
    </Layout>
  );
};

export default Probleme;
